<template>
	<div class="app-main__inner">
		<div class="main-card mb-3 mt-4">
			<div class="card-body">

				<div class="row mb-3">
					<div class="col-md-12">
						<div class="flex justify-content-end">
							<router-link :to="{name: 'sales'}"
										 class="cursor-pointer mr-2 back-btn font-semibold">
								<span class="text-xs-custom"><< {{ $t('back') }}</span>
							</router-link>
							<a href="javascript:void(0)" v-on:click="onOpenDrawer"
							   class="c-btn btn-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">
								<span class="text-xs-custom">{{ $t('triggerSync') }}</span>
							</a>

						</div>

					</div>
				</div>

				<div class="row mb-2 mt-3">
					<div class="col-md-12">
						<div class="main-card mb-3 card">
							<div class="card-body" style="    margin-top: 20px;">
								<div class="row sticky-table mb-3 mt-3">
									<div class="col-lg-4 col-md-4">
                                    <span>
                                        <el-date-picker
											class="w-full text-xs font-light app-input"
											v-model="dateRange"
											type="date"
											size="small"
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Startdatum"
											end-placeholder="Slutdatum">
                                        </el-date-picker>
                                    </span>
									</div>
									<div class="col-lg-4 col-md-4">
										<div class="flex w-full">
											<div
												class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
												{{ $t('apps') }}:
											</div>
											<div class="form-group w-full">

												<select id="app-name-id" v-model="app_type"
														class="text-xs form-select w-full h-8"
														style="height: 32px !important;">
													<option value="all">{{ $t('all') }}</option>
													<option :value="app.key" v-for="(app, i) in subscriptionList"
															:key="i">{{ app.name }}
													</option>
												</select>
											</div>
										</div>
									</div>
									<div class="col-lg-3 col-md-3">
										<div class="flex w-full">
											<div
												class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
												<!-- <i class="iconly-brokenFilter mr-1"></i> -->
												{{ $t('syncStatus') }}:
											</div>
											<div class="form-group w-full">
												<select class="text-xs form-select w-full removs-bg"
														style="height:32px !important;"
														v-model="statusType">
													<option value="all">{{ $t('all') }}</option>
													<option value="Success">{{ $t('success') }}</option>
													<!--													<option value="Pending">{{ $t('pending') }}</option>-->
													<option value="Failed">{{ $t('failed') }}</option>

												</select>
											</div>
										</div>
									</div>
									<div class="col-lg-1 col-md-1">
										<div class="flex justify-start lg:justify-end">
											<div class="form-group">
												<button v-on:click="generateReport"
														class="flex justify-content-center bg-purple-600 px-4 py-2 rounded-md text-white">
													<span v-if="generating=== true"><i class="fa fa-spinner fa-spin"></i></span>
													<span v-else>{{ $t('filter') }}</span>

												</button>
											</div>
										</div>
									</div>

								</div>
								<table style="width: 100%;margin-top: 15px" id="examplemmm" v-loading="vloading"
									   class="table table-hover table-striped table-bordered">
									<thead>
									<tr>
										<th class="text-xs">{{ $t('app_name') }}</th>
										<th class="text-xs">{{ $t('sync_type') }}</th>
										<th class="text-xs">{{ $t('date') }}</th>
										<th class="text-xs">{{ $t('syncStatus') }}</th>
										<th class="text-xs">{{ $t('message') }}</th>
										<th class="text-xs"></th>
									</tr>
									</thead>
									<tbody v-if="reports.results.length > 0" v-loading="vloading">
									<app-synchronize-row v-for="(item,key) in reports.results" :key="key" :item="item"/>
									</tbody>
									<tbody v-else>
									<tr>
										<td colspan="6" class="text-center">
											<span class="text-center"
												  style="text-align: center">{{ $t('no_date_available') }}</span>
										</td>
									</tr>
									</tbody>
								</table>
								<div class="float-right mb-5" v-if="reports.results.length > 0">
									<paginate
										:page-count="total_page"
										:click-handler="changePageNumber"
										:prev-text="$t('prev')"
										:next-text="$t('next')"
										:container-class="'pagination'">
									</paginate>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--		<div v-if="vloading === false" class="flex justify-center w-full mt-20">-->
		<!--			<svg class="animate-spin -ml-1 mr-3 h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none"-->
		<!--				 viewBox="0 0 24 24">-->
		<!--				<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>-->
		<!--				<path class="opacity-75" fill="currentColor"-->
		<!--					  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>-->
		<!--			</svg>-->
		<!--		</div>-->
	</div>
</template>

<script>
import AppSynchronizeRow from "./components/AppSynchronizeRow";
import {mapGetters} from "vuex"
import Paginate from 'vuejs-paginate'
import {
	ALLOWED_V3_APP_FOR_SYNC,
	ALLOWED_V3_SYNC_REPORT_APPS,
	ELEMENT_TYPES,
	ENABLED_V3_SYNC_APP
} from "../../app/index";
import Vue from "vue";
import {DatePicker} from "element-ui";

Vue.use(DatePicker)
export default {
	name: "AppSynchronizationPage",
	components: {
		AppSynchronizeRow,
		paginate: Paginate,
	},
	data: function () {
		return {
			showSyncPanel: true,
			vloading: true,
			loading: true,
			reports: {
				"count": 0,
				"next": null,
				"previous": null,
				"results": [],


			},
			total_page: 1,
			limit: 50,
			dateRange: '',
			value2: '',
			subscriptionList: [],
			app_types: [],
			elementTypes: ELEMENT_TYPES,
			statusType: 'all',
			app_type: 'all',
			generating: false,
		}
	},
	computed: {
		...mapGetters({
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_USER_SUBSCRIPTION: 'customer/GET_SUBSCRIPTION',
			USER_REFRESH: 'auth/USER_REFRESH'
		})
	},
	methods: {
		changePageNumber: function (num) {
			let offset = this.limit * num
			let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.APP_SYNC_REPORTS + `${this.GET_USER_COMPANY_ID}/?limit=${this.limit}&offset=${offset}&page=${num}` : this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}&page=${num}&offset=${offset}`
			if (this.dateRange) {
				URL += `&created_at=${this.dateRange}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription_id=${this.app_type}`
			}
			if (this.statusType !== 'all') {
				URL += `&status=${this.statusType}`
			}
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.reports = res.data
					this.total_page = Math.ceil(res.data.count / this.limit)
					if (this.total_page > 1) {
						this.total_page -= 1
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.changePageNumber(num)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onOpenDrawer: function () {
			window.Bus.$emit('open-app-sync-drawer')
		},
		getSyncReport(userID = null) {
			this.vloading = true
			const URL = userID ? this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}&user_id=${userID}` : this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}`
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					this.reports = res.data
					this.total_page = Math.ceil(res.data.count / this.limit)
					if (this.total_page > 1) {
						this.total_page -= 1
					}
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSyncReport(this.GET_USER_COMPANY_ID)
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		generateReport: function () {
			let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}&user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.APP_SYNC_REPORTS + `?limit=${this.limit}`
			// URL += `?limit=50`
			if (this.dateRange) {
				URL += `&created_at=${this.dateRange}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription__app_type=${this.app_type}`
			}
			if (this.statusType !== 'all') {
				URL += `&status=${this.statusType}`
			}
			this.generating = true
			this.$store.dispatch('customer/customGetRequest', {URL})
				.then(res => {
					this.generating = false
					this.reports = res.data
					this.total_page = Math.ceil(res.data.count / this.limit)
					if (this.total_page > 1) {
						this.total_page -= 1
					}
				}).catch(err => {
				this.generating = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.generateReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		getSubscriptionList() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL: URL})
				.then(_ => {
					// let subscriptionList = this.GET_USER_SUBSCRIPTION.all_subscriptions.filter(item => ALLOWED_V3_APP_FOR_SYNC.includes(parseInt(item.app_type)))
					try {
						ENABLED_V3_SYNC_APP.map(item => {
							let exist = this.GET_USER_SUBSCRIPTION.all_subscriptions.filter(sub => parseInt(sub.app_type) === parseInt(item.key))
							if (exist.length > 0) {
								this.subscriptionList.push(item)
							}
						})
					} catch (e) {
					}
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	},

	mounted() {
		// Implementing two-way fetching
		if (this.GET_USER_COMPANY_ID) {
			this.getSyncReport(this.GET_USER_COMPANY_ID)
		} else {
			this.getSyncReport()
		}
		this.getSubscriptionList()
		window.Bus.$on('reload-sync-event', e => {
			if (this.GET_USER_COMPANY_ID) {
				this.getSyncReport(this.GET_USER_COMPANY_ID)
			} else {
				this.getSyncReport()
			}
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}


.text-xs-custom {
	font-size: 14px !important;
	line-height: 1rem !important;
}

.btn-outline-pink {
	background: #fff;
	color: #ee4d81 !important;
	border: 1px solid #ee4d81 !important;
}

.back-btn {
	padding-top: 8px;
	color: #1045ca !important;
}
</style>
