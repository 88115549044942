<template>
	<tr>
		<td>{{ item.app_name }}</td>
		<td>{{ $t(`${item.sync_type}`) }}</td>
		<td>{{ item.company.company_name }}</td>
		<td>{{ item.company.username }}</td>
		<td>{{ $services.moment(item.created_at).format('YYYY-MM-DD HH:mm a') }}</td>
		<td>
			<span class="badge badge-warning text-white" v-if="item.status === 'Pending'">{{ $t('pending') }}</span>
			<span class="badge badge-success text-white" v-if="item.status === 'Success'">{{ $t('success') }}</span>
			<span class="badge badge-danger text-white" v-if="item.status === 'Failed'">{{ $t('failed') }}</span>
		</td>
		<td>
			<span v-if="GET_LOCALE === 'se'">{{ truncateString(item.message_swe, 100) }}</span>
			<span v-else-if="GET_LOCALE === 'en'">{{ truncateString(item.message, 100) }}</span>

		</td>
		<td><span v-if="['Success','Failed'].includes(item.status)" class="text-sky-600 cursor-pointer"
				  v-on:click="getReport"
				  style="color: #3f6ad7;">{{ $t('see_details') }}</span></td>
	</tr>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	name: "AppSynchronizeRow",
	props: {
		item: {
			required: true,
			type: Object
		},
		mode: {
			default: 'user'
		}
	},
	computed: {
		...mapGetters({
			GET_LOCALE: 'utility/GET_LOCALE_LANG'
		})
	},
	data: function () {
		return {
			syncType: {
				all_product: 'All Product',
				all_order: 'All Order',
				payment: 'Payment',
				order_specific: 'Order Specific',
				product_specific: 'Product Specific',
			}
		}
	},
	methods: {
		getReport: function () {
			window.Bus.$emit('open-app-sync-detail', this.item)

		},
		truncateString(str, num) {
			if (str) {
				if (str.length > num) {
					return str.slice(0, num) + "...";
				} else {
					return str;
				}
			} else {
				return ''
			}
		},
	}
}
</script>

<style scoped>

</style>
